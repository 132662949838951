import "../App.css";
import React from 'react';
import { Image, Collapse, Divider, Typography, Space, message, Button, Row,
  Col, Card, Input, Form, Menu, List, Icon, FloatButton, ConfigProvider } from 'antd';
const { Title, Text, Link } = Typography;


const ISMEDU = () =>  { 
  return <div>
    <h1 className="h1">ИШМ ЕДУ</h1>


    <p 
      className="text"
      style={{ color: 'red' }}>
      Страница је у развоју...
    </p>
<FloatButton.BackTop />
</div>
};
export default ISMEDU;